import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { PanelSize, PanelType } from './enums';
import localStyles from './Panel.module.pcss';
import type { PanelProps, PanelSectionProps, PanelSectionType } from './PanelProps';

/**
 * Creates panel styling.
 */
const Panel: ForwardRefExoticComponent<PropsWithoutRef<PanelProps> & RefAttributes<HTMLElement>> =
  forwardRef<HTMLElement, PanelProps>(function PanelForward(
    { type = PanelType.STANDARD, size = 'standard', className, id, children, ...props },
    ref
  ) {
    const cx = useClassNameMapper(localStyles);
    return (
      <article
        ref={ref}
        id={id}
        className={cx(
          className,
          { [`lia-panel-${type}`]: type !== PanelType.NONE },
          { 'lia-panel-size-compact': size === PanelSize.COMPACT }
        )}
        // support passing data-testid
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </article>
    );
  });

function createPanelSection(
  section: PanelSectionType,
  asDefault: React.ElementType
): React.FC<React.PropsWithChildren<PanelSectionProps>> {
  return function PanelSectionFactory({
    className,
    children,
    as: As = asDefault
  }: PanelSectionProps): React.ReactElement {
    const cx = useClassNameMapper(localStyles);
    return <As className={cx(className, `lia-panel-${section}`)}>{children}</As>;
  };
}

const PanelHeader = createPanelSection('header', 'header');
const PanelBody = createPanelSection('body', 'section');
const PanelFooter = createPanelSection('footer', 'footer');

export { Panel as default, PanelHeader, PanelFooter, PanelBody };
