import { useApolloClient } from '@apollo/client';
import { getCachedQueryVariables } from '@aurora/shared-apollo/cacheQueryVariables';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import Panel from '@aurora/shared-client/components/common/Panel/Panel';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import nodeViewsQuery from '@aurora/shared-client/components/nodes/NodeViews.query.graphql';
import useImperativeQueryWithTracing from '@aurora/shared-client/components/useImperativeQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import type {
  CoreNode,
  CoreNodeConstraints,
  CoreNodeEdge,
  CoreNodeSorts,
  ParentNode
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SortDirection } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useCallback, useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ApolloQueryCacheKey } from '../../../types/enums';
import type {
  NodeViewFragment,
  NodeViewsQuery,
  NodeViewsQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import NodeViewCard from '../NodeView/NodeViewCard/NodeViewCard';
import localStyles from './NodeViewDrawer.module.css';

interface Props {
  /**
   * The node to display.
   */
  node: NodeViewFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * the size of the the cards being rendered
   */
  cardSize?: NodeCardSize;
}

/**
 * Displays the child node in a drawer
 *
 * @author Amit Agrawal
 */
const NodeViewDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  className,
  cardSize
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.NODE_VIEW_DRAWER);
  const { contextNode } = useContext(AppContext);
  const { cache } = useApolloClient();
  const [nodeChildrenLoading, setNodeChildrenLoading] = useState<boolean>(false);
  const childrenEdges = (node as ParentNode)?.children?.edges.map(
    (item: CoreNodeEdge) => item.node
  );
  const pageInfo = (node as ParentNode)?.children?.pageInfo;

  const nodeChildren = useImperativeQueryWithTracing<NodeViewsQuery, NodeViewsQueryVariables>(
    module,
    nodeViewsQuery
  );

  /**
   * This function handles loading of the next set of child nodes under a parent node.
   * This is done by fetching the child nodes for their respective parent and updating the Apollo Cache manually.
   */
  const onShowMore = useCallback(async () => {
    setNodeChildrenLoading(true);
    const childrenConstraints: CoreNodeConstraints = {
      parentId: { eq: node.id }
    };
    const nodeSorts: CoreNodeSorts = {
      communityStructure: { direction: SortDirection.Asc, order: 0 },
      position: { direction: SortDirection.Asc, order: 1 }
    };
    const nestedNodesPageSize = 16;
    const nodeChildrenResult = await nodeChildren({
      constraints: childrenConstraints,
      sorts: nodeSorts,
      first: nestedNodesPageSize,
      after: pageInfo?.endCursor,
      useFullPageInfo: true,
      useNodeAvatar: true,
      useNodeDescription: true,
      useNodeParent: true,
      useNodeTopicsCount: true,
      useNodeLatestActivityTime: true,
      useNodeUnreadCount: true
    } as NodeViewsQueryVariables);

    const descendantEdges = nodeChildrenResult.data.coreNodes.edges;
    const cachedNodeViewQueryVariables: NodeViewsQueryVariables = getCachedQueryVariables(
      cache,
      `${ApolloQueryCacheKey.NODE_STRUCTURE}:${contextNode.id}`
    );
    const cachedNodeViewQuery = cache.readQuery<NodeViewsQuery, NodeViewsQueryVariables>({
      query: nodeViewsQuery,
      variables: cachedNodeViewQueryVariables
    });

    const updatedNodeEdges = [...cachedNodeViewQuery.coreNodes.edges].map((item, index) => {
      if (item.node.id === node.id) {
        const cachedNodeData = cachedNodeViewQuery.coreNodes.edges[index].node as ParentNode;
        const updatedNodeEdge = {
          ...cachedNodeViewQuery.coreNodes.edges[index],
          node: {
            ...cachedNodeData,
            children: {
              ...cachedNodeData?.children,
              edges: [...cachedNodeData?.children?.edges, ...descendantEdges],
              pageInfo: {
                ...nodeChildrenResult.data?.coreNodes.pageInfo
              }
            }
          }
        };
        return updatedNodeEdge;
      }
      return item;
    });

    const updatedData = {
      coreNodes: {
        ...cachedNodeViewQuery.coreNodes,
        edges: [...updatedNodeEdges]
      }
    };

    cache.writeQuery<NodeViewsQuery, NodeViewsQueryVariables>({
      query: nodeViewsQuery,
      variables: cachedNodeViewQueryVariables,
      data: updatedData as NodeViewsQuery
    });

    setNodeChildrenLoading(false);
  }, [cache, contextNode.id, node.id, nodeChildren, pageInfo?.endCursor]);

  if (textLoading) {
    return null;
  }

  /**
   * Displays a link that loads and appends results for the next page
   * to the current list of items.
   */
  function nodeChildrenPager(): React.ReactElement {
    const buttonTitle = formatMessage('title');
    return (
      <footer className={cx('lia-node-pager')}>
        <Button
          title={buttonTitle}
          loading={nodeChildrenLoading}
          variant={ButtonVariant.LINK}
          onClick={onShowMore}
          className={cx('lia-g-loader-btn')}
          data-testid="NodeChildren.Pager"
        >
          <Icon
            icon={Icons.ChevronDownIcon}
            size={IconSize.PX_16}
            color={IconColor.LOAD_TEXT}
            className={cx('lia-g-mr-5')}
          />
          {buttonTitle}
        </Button>
      </footer>
    );
  }

  /**
   * Renders the node view card for the drawer
   * @param childNode the child node to render
   */
  function renderNode(childNode: NodeViewFragment): React.ReactElement {
    return (
      <NodeViewCard
        entity={childNode}
        useNodeAvatar
        useNodeTitle
        nodeIconSize={IconSize.PX_80}
        className={cx('lia-node-children')}
        nodeTitleClassName={cx('lia-node-title')}
        useClickableCard
        isNestedNode
        nodeCardSize={cardSize}
      />
    );
  }

  return (
    <Panel className={cx(className, 'lia-node-drawer')} data-testid="NodeViewDrawer">
      <List<CoreNode | NodeViewFragment>
        items={childrenEdges}
        variant={{
          type: ListVariant.GRID,
          props: {
            colProps: { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 },
            itemSpacing: ListItemSpacing.LG
          }
        }}
      >
        {renderNode}
      </List>
      {pageInfo?.hasNextPage && nodeChildrenPager()}
    </Panel>
  );
};

export default NodeViewDrawer;
