import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { NodeFollowersCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from '../NodeMembersCount/NodeMembersCount.module.pcss';

interface Props {
  /**
   * The node for which followers count is displayed for.
   */
  node: NodeFollowersCountFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Displays the node followers count.
 *
 * @constructor
 *
 * @author Aditi Agarwal
 */
const NodeFollowersCount: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'span',
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_FOLLOWERS_COUNT
  );

  if (textLoading) {
    return null;
  }

  return (
    <Component className={cx(className, 'lia-node-followers-count')}>
      {formatMessage('followersCount', {
        count: node.subscriptionsCount > 0 ? node.subscriptionsCount : 0
      })}
    </Component>
  );
};

export default NodeFollowersCount;
